import React from 'react';
import styles from '../../styles/pages/facilities.module.css';
import { MdOutlineDoubleArrow } from 'react-icons/md'

function Membership() {
  return (
    <div className='body'>
        <div>
        <div className='heading'>
            MEMBERSHIP
        </div>
        <hr/>
        <hr/>
        <div className='body'>
        <div className={styles.text}>
        <p><MdOutlineDoubleArrow/> Become the member of the bank to avail easy loan facility:</p>
        
        <p><MdOutlineDoubleArrow/> Face value Rs. 100 per share</p>
                
        <p><MdOutlineDoubleArrow/> Maximum shares allotted minimum 10</p>
        <p><MdOutlineDoubleArrow/> Death relief fund is provided for members.</p>
        </div>
        </div>
        </div>
    </div>
  )
}

export default Membership