import React from 'react'
import styles from '../../styles/pages/loans_deposits.module.css'
import { MdOutlineDoubleArrow } from 'react-icons/md'

function ReccuringDeposit() {
    let features = ["Minimum initial deposit amount is Rs.100/- or multiples thereof with no. Ceiling for the maximum deposit amount.",
"Minimum Deposit period is 12 months.",
"Maximum Deposit period is 120 months.",
"The Deposits in this type of account will earn compound interest on quarterly basis.",
"Individual Deposit/ Joint Deposit / Deposit in the name of Minors can be made.",
"Additional Interest Rate for senior citizen.",
"Nomination facility is available.",
"Loan can be granted up to 75% of the deposit amount against the pledge of Deposit as per Existing norms.",
"It is mandatory for the depositor to deposit the core deposit amount every month"];
        
        let documents = ["Passport",
                         "Driving license",
                         "PAN Card",
        "Voter's Identity Card",
        "NAREGA Job Card",
        "Aadhar Card"];
        
          return (
            <div className='body'>
              <div className='heading'>
              RECURRING DEPOSIT
              </div>
              <hr/>
              <hr/>
              <div className={styles.text}>
                    <p>
                    The Talikoti Sahakari Bank providing deposit account which enables a depositor to save by paying into the account an agreed minimum sum of money monthly like "A penny saved is a penny earned" over a stipulated period.
                    </p>
                </div>
              <div className='body bordered_box'>
              
                <div className={styles.subheading}>
                    Eligibility 
                </div>
                <div className={styles.text}>
                    <p>
                    Recurring Deposit can be opened by Individuals/Minors/Institutions/Associations/Regd.Co-op. Societies/Trusts/HUFs
                    </p>
                </div>
        
                <div className='break'></div>
        
                <div className={styles.subheading}>
                    Features & Benefits: 
                </div>
                <div className={styles.text}>
                    {features.map(text => <p><MdOutlineDoubleArrow/>{text}</p>)}
                </div>
        
                <div className='break'></div>
        
                <div className={styles.subheading}>
                    Officially Valid Documents:
                </div>
                <div className={styles.text}>
                   <p> Two latest passport size photographs and any one of the officially valid documents as Identity and Address proof as below,</p>
                    {documents.map(text => <p><MdOutlineDoubleArrow/>{text}</p>)}
                </div>
              </div>
            </div>
          )
}

export default ReccuringDeposit