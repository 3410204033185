import { React, useState, useEffect } from 'react'
import '../styles/pages/homepage.css'
import gold_loan from '../assets/images/goldloan.png'
import vehicle_loan from '../assets/images/vehicleloan.png'
import home_loan from '../assets/images/homeloan.png'
import business_loan from '../assets/images/business_loan.png'
import { Link } from 'react-router-dom'
import Button from '../components/Button'
import { MdOutlineDoubleArrow } from "react-icons/md";
import about_img from '../assets/images/about_img.png'
import { FaMapMarkerAlt } from "react-icons/fa";
import { RiDoubleQuotesL } from "react-icons/ri";
import about_us from '../assets/images/about_us.jpg';
import interest_rates from '../assets/images/interest_rates.webp';
import deposit from '../assets/images/deposits.webp'
import gallery from '../assets/images/gallery.png'
import Slider from '../components/Slider'
import SimpleCard from '../components/SimpleCard'
import Popup from 'reactjs-popup'
import Modal from '../components/Modal'
import ModalArray from '../components/ModalArray'
import ModalFileInput from '../components/ModalFileInput'


function HomePage() {
    const image_list = [
        {
            text: "Gold Loan",
            img: gold_loan,
            link: ''
        },
        {
            text: "Vehicle Loan",
            img: vehicle_loan,
            link: ''
        },
        {
            text: "Home Loan",
            img: home_loan,
            link: ''
        },
        {
            text: "Business Loan",
            img: business_loan,
            link: ''
        }
    ];

    const [image, setImage] = useState();
    const [landingText, setLandingText] = useState("");
    const [welcomeText, setwelcomeText] = useState("");
    const [welcomeTextID, setwelcomeTextID] = useState("");
    const [news, setNews] = useState([]);
    const [open, setOpen] = useState(false);

    const closeModal = () => setOpen(false);    

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch("http://localhost:5000/homepage");
            const json = await response.json();
            if (response.ok) {
                setLandingText(json[0].content);
                setNews(json[1].news);
                const image = document.getElementById("aboutImg");
                image.src = json[2].file;
            }
        }
        fetchData();
        setTimeout(() => setOpen(true), 1000);
    }, []);
    // fetching data for welcome section
    useEffect(() => {
        const fetchData = async () => {
            const resp = await fetch("http://localhost:5000/welcomePageController");
            const json = await resp.json();
           
            if (resp.ok) {
                setwelcomeText(json[0].para);
                setwelcomeTextID(json[0]._id);
            }
        }
        fetchData();
        setTimeout(() => setOpen(true), 1000);
    }, []);

    const postLandingSectionData = async (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget).get("landingSectionText");
        setLandingText(data);
        
        const response = await fetch("http://localhost:5000/landingsection", {
            method: "POST",
            body: JSON.stringify({
                label: "homepageText",
                content: data,
            }),
            headers: {
                "Content-type": "application/json"
            }
        });
    }

    const postWelcomsectionData = async (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget).get("welcomeSectionData");
       setwelcomeText(data);
        
        const response = await fetch(`http://localhost:5000/welcomePageController/${welcomeTextID}`, {
            method: "PUT",
            body: JSON.stringify({
                label: "welcomepageText",
                para: data,
            }),
            headers: {
                "Content-type": "application/json"
            }
        });
    }

    const postNewsData = async (content) => {
        setNews(content);
        const response = await fetch("http://localhost:5000/news", {
            method: "POST",
            body: JSON.stringify({
                label: "news",
                news: content,
            }),
            headers: {
                "Content-type": "application/json"
            }
        });        
    }

    const postFileData = async (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget).get("temp");
        const str = URL.createObjectURL(data);
        const image = document.getElementById("aboutImg");
        image.src = str;
        const response = await fetch(`http://localhost:5000/postFileData`, {
            method: "POST",
            body: JSON.stringify({
                label: "file",
                file: str,
            }),
            headers: {
                "Content-type": "application/json"
            }
        });
    }
  
    return (

        <div className='container'>
            <div>
                <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                    <div className="modal">
                        <a className="close" onClick={closeModal}>
                            &times;
                        </a>
                        <p>THE TALIKOTI SAHAKARI BANK NIYAMIT</p>
                        <div className='content'>
                            <p>As per Reserve Bank of India Circular dated 01.12.2022, we hereby declare our Bank as </p>

                            <p>"Financially Sound and Well Managed (FSWM) Bank"</p>
                            <p>For FY 2023-24</p>
                        </div>

                    </div>
                </Popup>
            </div>
            
            <section className='intro'>
                <RiDoubleQuotesL />
                <div className='intro_grid'>
                    <div className='grid_two'>                   
                        <Modal onSubmit={postLandingSectionData} value={landingText} label="landingSectionText"/>
                        <p className='headingText'>About Us:</p>
                        <p>{landingText}</p>
                    </div>
                    <div><ModalFileInput onSubmit={postFileData} value={"ENter"} label="temp"/><Link to="/about"><img id="aboutImg" /><p className='subtext'>About Us</p></Link></div>
                    <div><Link to="/deposits/savings-account"><img src={deposit} /><p className='subtext'>Deposits</p></Link></div>
                    <div><Link to="/deposit-interest-rates"><img src={interest_rates} /><p className='subtext'>Interest Rates</p></Link></div>
                    <div><Link to="/gallery"><img src={gallery} /><p className='subtext' >Gallary</p></Link></div>
                </div>
            </section>

            <div className='break' />

            <section className='section news_container'>
                <p className='subtitle'>LATEST NEWS</p>
                
                <ModalArray onSubmit={postNewsData} value={news} label = "news"/>
                <div className='news'>
                    <div className='items'>
                        
                        {news.map((news) => {
                            return <div>
                            <MdOutlineDoubleArrow />
                            <span>{news}</span>
                        </div>
                        })}
                        
                        


                    </div>
                </div>
            </section>

            <div className='break' />

            <section className='section loans'>
                <p className='subtitle'>LOANS</p>
                <div>
                    <SimpleCard list={image_list} />
                </div>
                <Button text="View More" />
            </section>

            <div className='break' />
            <div className='break' />

            <section className='section'>
                <p className='subtitle'>FACILITIES</p>
                <div className='facilities'>
                    <Slider />
                </div>
            </section>


            <section className='about'>
              <Modal onSubmit={postWelcomsectionData} value={welcomeText} label="welcomeSectionData"/>
                <p className='subtitle hello'>WELCOME TO THE TALIKOTI SAHAKARI BANK NIYAMIT!</p>
                <p className='content' style={{whiteSpace:"pre-line"}}>
                    <img src={about_img} />
                    {welcomeText}
                    {/* Established on 28/03/1960 and licensed by Reserve Bank of India to carry on
                    banking business The Talikoti Sahakari Bank Niyamit, Talikoti is today reckoned as one of the
                    top-notch & leading amongst the Urban Co-operative banks operating in the region of North Karnataka.
                    In the six decades of its existence, the performance of bank has been prototypical in terms of
                    growth in deposits, advances & overall operational preeminent.
                    <br /> <br />
                    With a view to extend banking
                    services to the lower and middle class people of Talikoti and its surrounding villages, The Talikoti
                    Sahakari Bank is providing devised need based loan schemes, keeping in view every imaginable need of
                    an individual borrower and business holders. */}

                </p>

            </section>

            
              
            
            <div className='break' />

            <section className='section branches'>
                <FaMapMarkerAlt />
                <p>Want to visit us in person? <br /><span><Link to='/branches-and-bank-timings'>Check out all of our branches --{">"}</Link></span></p>
            </section>
            <div className='break' />
        </div>


    )
}

export default HomePage