import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/components.css'

function SimpleCard(props) {
    const image_list = props.list;
  return (
    <div className='simple_card'>
                    {image_list.map(img => 
                        <div>                            
                            <Link><img src={img.img}></img></Link>
                            <p><span>{img.name}</span><br/>
                            {img.title}</p>
                        </div>
                    )}                    
    </div>
  )
}

export default SimpleCard