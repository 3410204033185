import React, { useState } from "react";
import Popup from "reactjs-popup";
import Button from "./Button";
import { FaEdit } from "react-icons/fa";
import { TextArea } from "./TextInput";

function Modal(props) {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(!open);
  }
  return (
    <div className="modalDiv">
      <FaEdit onClick={onClick} />

      <Popup className="editModal" open={open} closeOnDocumentClick={false}>
        <a className="close" onClick={onClick}>
          &times;
        </a>
        <p>Edit</p>
        <div>

          <form onSubmit={props.onSubmit}>
          <label for="heading">Heading</label>      

          <input type="text" id="heading"></input>
          <TextArea label={props.label} text = {props.value}/>

            <button>Submit</button>
          </form>
        </div>
      </Popup>
    </div>
  )

}


export default Modal;
