import React from 'react'
import styles from '../styles/pages/aboutuspage.module.css'
import SimpleCard from '../components/SimpleCard_extraText'
import V_A_Hajeri from '../assets/images/V_A_Hajeri.png'
import H_B_BAGEWADI from '../assets/images/H_B_BAGEWADI.png'
import K_C_Sajjan from '../assets/images/K_C_Sajjan.png'
import I_B_Bilebhavi from '../assets/images/I_B_Bilebhavi.png'
import M_S_Sarasheeti from '../assets/images/M_S_Sarasheeti.png'
import D_S_Hebsur from '../assets/images/D_S_Hebsur.png'
import C_S_Yalagi from '../assets/images/C_S_Yalagi.png'
import N_I_Chinagudi from '../assets/images/N_I_Chinagudi.png'
import S_C_Patil from '../assets/images/S_C_Patil.png'
import R_S_Hiremath from '../assets/images/R_S_Hiremath.png'
import R_B_Kattimani from '../assets/images/R_B_Kattimani.png'
import A_C_Bableshwar from '../assets/images/A_C_Bableshwar.png'
import S_Y_Baradenal from '../assets/images/S_Y_Baradenal.png'
import R_A_Muragi from '../assets/images/R_A_Muragi.png'
import M_B_Konnur from '../assets/images/M_B_Konnur.png'
import logo from '../assets/images/logo.png'







function BoardOfDirectors() {
    const pres =[
        
        {
            name: "SHRI H. B. BAGEWADI",
            title : "PRESIDENT",
            img : H_B_BAGEWADI,
        }
    ];

    const directors = [
        {
            name: "SHRI K. C. SAJJAN",
            title : "DIRECTOR",
            img : K_C_Sajjan,
        },
        {
            name: "SHRI I. B. BILEBHAVI",
            title : "DIRECTOR",
            img : I_B_Bilebhavi,
        },
        {
            name: "SHRI M. S. SARASHETTI",
            title : "DIRECTOR",
            img : M_S_Sarasheeti,
        },
        {
            name: "SHRI D. S. HEBASUR",
            title : "DIRECTOR",
            img : D_S_Hebsur,
        },
        {
            name: "SHRI C. S. YALAGI",
            title : "DIRECTOR",
            img : C_S_Yalagi,
        },
        {
            name: "SHRI N. I. CHINAGUDI",
            title : "DIRECTOR",
            img : N_I_Chinagudi,
        },
        {
            name: "SHRI S. C .PATIL",
            title : "DIRECTOR",
            img : S_C_Patil,
        },
        {
            name: "SHRI R. S. HIREMATH",
            title : "DIRECTOR",
            img : R_S_Hiremath,
        },
        {
            name: "SHRI R. B. KATTIMANI",
            title : "DIRECTOR",
            img : R_B_Kattimani,
        },
        {
            name: "SHRI S. Y. BARADENAL",
            title : "DIRECTOR",
            img : S_Y_Baradenal,
        },
        {
            name: "SMT. A. C. BABALESHWAR",
            title : "DIRECTOR",
            img : A_C_Bableshwar,
        },
        {
            name: "SHRI R. A. MURAGI",
            title : "PROF. DIRECTOR",
            img : R_A_Muragi,
        },
        {
            name: "SHRI M. B. KONNUR",
            title : "PROF. DIRECTOR",
            img : M_B_Konnur,
        }
    ];
  return (
    
    <div className='body'>
        <img className={styles.logo_bg} src={logo}/>
        
        <div className='heading'>
            BOARD OF DIRECTORS
        </div>
        <hr/>
            <hr/>
        <div className='body'>
        <div className={styles.pres}>
            <SimpleCard list={pres}/>
        </div>

        <div className={styles.directors}>
            <SimpleCard list = {directors}/>
        </div>
        </div>
    </div>
  )
}

export default BoardOfDirectors