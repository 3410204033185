// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallerypage_gallery__Drp9T > div > div{
    background-color: var(--secondary_bg);
    margin-inline: 25px;
}

.gallerypage_gallery__Drp9T > div{
    padding-inline: 0px;
}

.gallerypage_gallery__Drp9T > div a img{
    width: 100%;
}

.gallerypage_gallery__Drp9T > div p{
    font-weight: 700;
    color: var(--secondary);
}

.gallerypage_gallery__Drp9T{
    height: 60vh;
}

.gallerypage_photo_gallery__QuFDF > div{
    max-width: 350px;
}

.gallerypage_photo_gallery__QuFDF > div p{
    font-size: 15px;
    font-weight: 600;
}


@media screen and (max-width: 600px){
    .gallerypage_gallery__Drp9T > div p{
        margin-left: 0px;
        font-size: 8px;
    }

    .gallerypage_gallery__Drp9T > div > div{
        margin-inline: 15px;
    }

    .gallerypage_gallery__Drp9T > div{
        padding-top: 0px;
    }
}


@media screen and (min-width: 600px){
    .gallerypage_gallery__Drp9T > div p{
        margin-left: 0px;
        font-size: 12px;
    }

    .gallerypage_gallery__Drp9T > div > div{
        margin-inline: 15px;
    }

    .gallerypage_gallery__Drp9T > div{
        padding-top: 0px;
    }
}


@media screen and (min-width: 768px){
    .gallerypage_gallery__Drp9T > div p{
        font-size: 14px;
    }
}


@media screen and (min-width: 1200px){
    .gallerypage_gallery__Drp9T > div p{
        font-size: 18px;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/gallerypage.module.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;;AAGA;IACI;QACI,gBAAgB;QAChB,cAAc;IAClB;;IAEA;QACI,mBAAmB;IACvB;;IAEA;QACI,gBAAgB;IACpB;AACJ;;;AAGA;IACI;QACI,gBAAgB;QAChB,eAAe;IACnB;;IAEA;QACI,mBAAmB;IACvB;;IAEA;QACI,gBAAgB;IACpB;AACJ;;;AAGA;IACI;QACI,eAAe;IACnB;AACJ;;;AAGA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".gallery > div > div{\r\n    background-color: var(--secondary_bg);\r\n    margin-inline: 25px;\r\n}\r\n\r\n.gallery > div{\r\n    padding-inline: 0px;\r\n}\r\n\r\n.gallery > div a img{\r\n    width: 100%;\r\n}\r\n\r\n.gallery > div p{\r\n    font-weight: 700;\r\n    color: var(--secondary);\r\n}\r\n\r\n.gallery{\r\n    height: 60vh;\r\n}\r\n\r\n.photo_gallery > div{\r\n    max-width: 350px;\r\n}\r\n\r\n.photo_gallery > div p{\r\n    font-size: 15px;\r\n    font-weight: 600;\r\n}\r\n\r\n\r\n@media screen and (max-width: 600px){\r\n    .gallery > div p{\r\n        margin-left: 0px;\r\n        font-size: 8px;\r\n    }\r\n\r\n    .gallery > div > div{\r\n        margin-inline: 15px;\r\n    }\r\n\r\n    .gallery > div{\r\n        padding-top: 0px;\r\n    }\r\n}\r\n\r\n\r\n@media screen and (min-width: 600px){\r\n    .gallery > div p{\r\n        margin-left: 0px;\r\n        font-size: 12px;\r\n    }\r\n\r\n    .gallery > div > div{\r\n        margin-inline: 15px;\r\n    }\r\n\r\n    .gallery > div{\r\n        padding-top: 0px;\r\n    }\r\n}\r\n\r\n\r\n@media screen and (min-width: 768px){\r\n    .gallery > div p{\r\n        font-size: 14px;\r\n    }\r\n}\r\n\r\n\r\n@media screen and (min-width: 1200px){\r\n    .gallery > div p{\r\n        font-size: 18px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gallery": `gallerypage_gallery__Drp9T`,
	"photo_gallery": `gallerypage_photo_gallery__QuFDF`
};
export default ___CSS_LOADER_EXPORT___;
