import React, { useLayoutEffect } from "react";
import styles from '../styles/pages/loans_deposits.module.css'

function tab_change(id){
    let next_tab = document.getElementsByClassName(id)[0];
    const next_label = document.getElementById(id);
    let current = document.getElementsByClassName("active")[0];
    let current_label = document.getElementsByClassName("activeTab")[0];

    current.classList.remove('active');
    current_label.classList.remove('activeTab');

    next_tab.classList.add('active');
    next_label.classList.add('activeTab');
}

function Loans(){
    useLayoutEffect(() => {
        window.scrollTo(0,0);
    });
    const loans = [
        {
            className : "goldloan",
            text : 'GOLD LOAN',
            purpose : "Loan against pledge of Gold",
            int_rate: "10%",
            tenure : "12 months",
            req : "Customer of Bank",
            margin: "25%",
            active: "active",
            dropdown : false,
        },
        {
            className : "hypothecation",
            text : 'Hypothecation Cash Credit',
            purpose : "Loan against stock and receivables/Working Capital",
            int_rate: "14% upto 1 lakh",
            tenure : "12 months",
            req : "1. Last 3 years financial statement\n\n2. All other information related to business \n\n3. All other documents related to collateral security",
            margin: "25%",
            active: "",
            dropdown : false,
        },
        {
            className : "termloan",
            text : 'Term Loan(Secured)',
            purpose : "Business Loan/Individual Loan",
            int_rate: "13% upto 10 lakh \n\n12% to 25 lakh and above",
            tenure : "12 months",
            req : "1. Last 3 years financial statement\n\n2. All other information related to business \n\n3. All other documents related to collateral security",
            margin: "25%",
            active: "",
            dropdown : false,
        },
        {
            className : "termloanP",
            text : 'Term Loan(Personal)',
            purpose : "Salary Loan",
            int_rate: "14% upto 5 lakhs",
            tenure : "36 months to 60 months",
            req : "1. Latest salary certificate\n\n2. Last 6 months pass book\n\n3. Form No.60 or ITR",
            margin: "-",
            active: "",
            dropdown : false,
        },
        {
            className : "housingloan",
            text : 'Housing Loan',
            active: "",
            dropdown : [true, [
                {
                    className: "purchase_construction",
                    text:"Purchase/Construction",
                    purpose : "Purchase or construction of houses or purchase of flats",
                    int_rate: "12% upto 50 lakh \n\n11% to 50 lakh and above",
                    tenure : "Maximum 120 months",
                    req : "Estimate of purchase price or cost of construction",
                    margin: "25% to 50%",
                },
                {
                    className: "repair_renewal",
                    text:"Repair/Renewal",
                    purpose : "Repair/Renewal of housing",
                    int_rate: "12%",
                    tenure : "Maximum 60 months",
                    req : "Estimate of repair",
                    margin: "50%",
                }
            ]],
        },
        {
            className : "vehicleloan",
            text : 'Vehicle Loan',
            active: "",
            dropdown : [true, [
                {
                    className: "two_wheeler",
                    text:"Two Wheeler",
                    purpose : "Two Wheeler vehicles",
                    int_rate: "12%",
                    tenure : "Maximum 60 months",
                    req : "Quotation of vehicle by authorized dealer",
                    margin: "25%",
                },
                {
                    className: "four_wheeler",
                    text:"Four Wheeler",
                    purpose : "Four Wheeler vehicles",
                    int_rate: "12%",
                    tenure : "Maximum 60 months",
                    req : "Quotation of vehicle by authorized dealer",
                    margin: "25%",
                },
                {
                    className: "four_wheeler_used",
                    text:"Four Wheeler(Used)",
                    purpose : "Four Wheeler vehicles(Used)",
                    int_rate: "12%",
                    tenure : "Maximum 60 months",
                    req : "Quotation of vehicle by authorized dealer",
                    margin: "50%",
                },
            ]],
        },
    ];
    return (
        <div className = {`${'body'} ${styles.body}`}>
            <div className={styles.tabs}>
                {
                    //side navigation bar
                    loans.map(item => {
                        if(item.dropdown[0]){
                            return <div>
                                    <a id={item.className} className={item.active+"Tab"}>{item.text}</a>
                                    <div className={styles.subTabs}>
                                        { item.dropdown[1].map(i => {
                                                return <a tabindex="0" id={i.className} className={item.active+"Tab"} onClick={() => tab_change(i.className)}>{i.text}</a>
                                        })}
                                    </div>
                                </div>
                        }
                        else{
                            return <a id={item.className} className={item.active+"Tab"} onClick={() => tab_change(item.className)}>{item.text}</a>
                        }
                        
                    })
                }
            </div>

                
            {
                //Each loan tab
                loans.map(item => {
                    if(item.dropdown[0]){
                        return item.dropdown[1].map(i => {
                            return <div className={`${i.className} ${styles.loans} ${item.active}`}>
                        <div className={`${'heading'} ${styles.heading}`}>
                            {i.text}
                        </div>
                        <hr/>
                        <hr/>
                        <div>
                            <div className={styles.table}>
                                <div className={styles.row}>
                                    <p className={styles.head}> PURPOSE</p>
                                    <p>{i.purpose}</p>
                                </div>

                                <div className={styles.row}>
                                    <p className={styles.head}>INTEREST RATE</p>
                                    <p>{i.int_rate}</p>
                                </div>

                                <div className={styles.row}>
                                    <p className={styles.head}> TENURE</p>
                                    <p>{i.tenure}</p>
                                </div>

                                <div className={styles.row}>
                                    <p className={styles.head}>REQUIREMENT</p>
                                    <p>{i.req}</p>
                                </div>

                                <div className={styles.row}>
                                    <p className={styles.head}>{"MARGIN(Min)"}</p>
                                    <p>{i.margin}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                        })
                }

                    else{
                        return <div className={`${item.className} ${styles.loans} ${item.active}`}>
                        <div className={`${'heading'} ${styles.heading}`}>
                            {item.text}
                        </div>
                        <hr/>
                        <hr/>
                        <div>
                            <div className={styles.table}>
                                <div className={styles.row}>
                                    <p className={styles.head}> PURPOSE</p>
                                    <p>{item.purpose}</p>
                                </div>

                                <div className={styles.row}>
                                    <p className={styles.head}>INTEREST RATE</p>
                                    <p>{item.int_rate}</p>
                                </div>

                                <div className={styles.row}>
                                    <p className={styles.head}> TENURE</p>
                                    <p>{item.tenure}</p>
                                </div>

                                <div className={styles.row}>
                                    <p className={styles.head}>REQUIREMENT</p>
                                    <p>{item.req}</p>
                                </div>

                                <div className={styles.row}>
                                    <p className={styles.head}>{"MARGIN(Min)"}</p>
                                    <p>{item.margin}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    
                })
            }
            
        </div>
    )
}

export default Loans