// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loans_deposits_subheading__EvWYD{
    font-weight: 800;
    color: var(--secondary);
}

.loans_deposits_text__EX8pE{
    padding-left:20px;
}

.loans_deposits_text__EX8pE svg{
    color: var(--secondary);
    vertical-align: middle;
    margin-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/loans_deposits.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,uBAAuB;IACvB,sBAAsB;IACtB,kBAAkB;AACtB","sourcesContent":[".subheading{\r\n    font-weight: 800;\r\n    color: var(--secondary);\r\n}\r\n\r\n.text{\r\n    padding-left:20px;\r\n}\r\n\r\n.text svg{\r\n    color: var(--secondary);\r\n    vertical-align: middle;\r\n    margin-right: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subheading": `loans_deposits_subheading__EvWYD`,
	"text": `loans_deposits_text__EX8pE`
};
export default ___CSS_LOADER_EXPORT___;
