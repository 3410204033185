import React from 'react'
import "../styles/components.css"

function TextInput(props) {
  return (
    <div>
        <label for="heading"></label>
        <input name={props.label+props.id} type="text" id="heading" defaultValue={props.text}></input>
    </div>
  )
}

function TextArea(props) {
    return (
        <div>
            <label for="textArea">Content</label>        
            <textarea name={props.label} id="textArea"  defaultValue={props.text}></textarea>
        </div>
    )
}

function FileInput(props) {
  return (
    <div>
      <label for="fileInput">Choose File</label>
      <input name={props.label} type='file' accept='.png, .pdf, .jpg, jpeg'/>
    </div>
  )
}

export {TextInput, TextArea, FileInput}