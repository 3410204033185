import Navbar from "./components/Navbar";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";
import AboutUs from "./pages/AboutUs";
import BoardOfDirectors from "./pages/BoardOfDirectors";
import ManagementExecutives from "./pages/ManagementExecutives";
import BranchesTImings from "./pages/BranchesTimings";
import AnnualReport from "./pages/AnnualReport";
import FuturePlans from "./pages/FuturePlans";
import DepositInterestRates from "./pages/DepositInterestRates";
import LoanInterestRates from "./pages/LoanInterestRates";
import Gallery from "./pages/Gallery/Gallery";
import PhotoGallery from "./pages/Gallery/PhotoGallery";
import ContactUs from "./pages/ContactUs";
import SavingsAccount from "./pages/Deposits/SavingsAccount";
import CurrentAccount from "./pages/Deposits/CurrentAccount";
import FixedDeposit from './pages/Deposits/FixedDeposit'
import RecurringDeposit from "./pages/Deposits/RecurringDeposit";
import PigmyDeposit from "./pages/Deposits/PigmyDeposit";


function App() {
  return (
    <div className="App">
      <Navbar/>
      <Routes>
        <Route path="/" element ={ <HomePage/> }/>
        <Route path="/about" element={<AboutUs/>}/>
        <Route path="/bod" element={<BoardOfDirectors/>}/>
        <Route path="/management-executives" element={<ManagementExecutives/>}/>
        <Route path="/branches-and-bank-timings" element= {<BranchesTImings/>}/>
        <Route path="/annual-report" element= { <AnnualReport/> }/>
        <Route path="/future-plans" element= { <FuturePlans/> }/>
        <Route path="/deposit-interest-rates" element= { <DepositInterestRates/> }/>
        <Route path="/loan-interest-rates" element= { <LoanInterestRates/> }/>
        <Route path="/contact-us" element= { <ContactUs/> }/>
        <Route path="/gallery" element= { <Gallery/> }></Route>
      </Routes>

      <Routes>
        
        <Route path="/gallery/photo-gallery" element ={ <PhotoGallery/> }/>
        <Route path="/gallery/latest-events" element ={ <PhotoGallery/> }/>
        <Route path="/gallery/video-gallery" element ={ <PhotoGallery/> }/>
      </Routes>

      <Routes>
        
        <Route path="/deposits/savings-account" element ={ <SavingsAccount/> }/>
        <Route path="/deposits/current-account" element ={ <CurrentAccount/> }/>
        <Route path="/deposits/fixed-deposit" element ={ <FixedDeposit/> }/>
        <Route path="/deposits/recurring-deposit" element ={ <RecurringDeposit/> }/>
        <Route path="/deposits/daily-deposit" element ={ <PigmyDeposit/> }/>
      </Routes>
      <Footer/>
    </div>
  );
}


export default App;
