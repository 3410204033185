import React from 'react'
import '../styles/components.css'
import { HiMiniBanknotes } from "react-icons/hi2";
import Button from './Button';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';


function GradientCard(props) {
    const IconProp: React.JSX.Element = props.icon;
  return (
    <Link to={props.link}><div className='card'>
        <div className='icon'><IconProp/></div>
        <h3>{props.title}</h3>
        <p>{props.desc}</p>
    </div></Link>
  )
}

function GradientCardNoIcon(props){
  return (
    <div className='cardNoIcon card'>
        <h3>{props.title}</h3>
        <p><span>{props.title1}</span>{props.desc1}</p>
        <p><span>{props.title2}</span>{props.desc2}</p>
        <Button text="Map"></Button>
    </div>
  )
}

export {GradientCard, GradientCardNoIcon};
