import React, { useLayoutEffect } from 'react';
import styles from '../../styles/pages/facilities.module.css';
import { MdOutlineDoubleArrow } from 'react-icons/md'

function ABB_Services() {
    useLayoutEffect(() => {
      window.scrollTo(0,0);
  });

  return (
    <div className='body'>
        <div>
        <div className='heading'>
            ABB SERVICES
        </div>
        <hr/>
        <hr/>
        <div className='body'>
        <div className={styles.text}>
        <p><MdOutlineDoubleArrow/> Cash Deposits</p>
        
        <p><MdOutlineDoubleArrow/> Cash Withdrawals by cheque / Slip</p>
                
        <p><MdOutlineDoubleArrow/> Fund Transfer between accounts / branches</p>
        <p><MdOutlineDoubleArrow/> Avail Cheque Books</p>
        <p><MdOutlineDoubleArrow/> Statement of Account</p>
        </div>
        </div>
        </div>
    </div>
  )
}

export default ABB_Services