// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Croissant+One&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Croissant+One&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
  --primary:rgb(67, 195, 241);
  --secondary:rgb(1, 53, 72);
  --primary_bg: rgba(1, 157, 216, 0.2);
  --primary_dark:rgb(0, 43, 59);
  --secondary_bg: rgb(219, 245, 255);
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue' ,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.heading{
  font-family: "Croissant One", serif;
    margin: 0px;
    margin-left: 50px;
    font-size: 28px;
    padding: 5px;
    color: var(--secondary);
}

.body{
  padding-inline: 50px;
  padding-block: 30px;
}


.break{
  height: 40px;
}

a{
  text-decoration: none;
  color: inherit;
}

.react-pdf__Page__canvas{
  width: 90vw !important;
  height: auto !important;
  overflow: clip;
}

.bordered_box{
  border: 2px solid var(--secondary);
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAGA;EACE,2BAA2B;EAC3B,0BAA0B;EAC1B,oCAAoC;EACpC,6BAA6B;EAC7B,kCAAkC;AACpC;;AAEA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,mCAAmC;IACjC,WAAW;IACX,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;AACrB;;;AAGA;EACE,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,kCAAkC;EAClC,mBAAmB;AACrB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Croissant+One&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Croissant+One&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');\n\n:root{\n  --primary:rgb(67, 195, 241);\n  --secondary:rgb(1, 53, 72);\n  --primary_bg: rgba(1, 157, 216, 0.2);\n  --primary_dark:rgb(0, 43, 59);\n  --secondary_bg: rgb(219, 245, 255);\n}\n\nbody {\n  margin: 0;\n  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue' ,\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.heading{\n  font-family: \"Croissant One\", serif;\n    margin: 0px;\n    margin-left: 50px;\n    font-size: 28px;\n    padding: 5px;\n    color: var(--secondary);\n}\n\n.body{\n  padding-inline: 50px;\n  padding-block: 30px;\n}\n\n\n.break{\n  height: 40px;\n}\n\na{\n  text-decoration: none;\n  color: inherit;\n}\n\n.react-pdf__Page__canvas{\n  width: 90vw !important;\n  height: auto !important;\n  overflow: clip;\n}\n\n.bordered_box{\n  border: 2px solid var(--secondary);\n  border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
