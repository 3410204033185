import React, { useLayoutEffect } from 'react'
import styles from '../styles/pages/aboutuspage.module.css'
import { GradientCardNoIcon } from '../components/GradientCard'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { FaClock } from "react-icons/fa6";

function BranchesTimings() {
    useLayoutEffect(() => {
        window.scrollTo(0,0);
    });
    const branches=[
        {
            title: "HEAD OFFICE/MAIN BRANCH",
            title1: 'Address : ',
            desc1:'At Omkar Chowk, Bijapur Circle Talikot',
            title2:'Branch Manager : ',
            desc2:'Shri. S. S. Gadigeppagol',
            latitude: '16.476803886494807',
            longitude: '76.30652101434107',
        },
        {
            title: "MINAJAGI BRANCH",
            title1: 'Address : ',
            desc1:'Near Minajagi Bus Stop, Minajagi',
            title2:'Branch Manager : ',
            desc2:'Shri. R. S. Biradar',
            latitude: '16.472877275721416',
            longitude: '76.27308021435806',
           
        },
        {
            title: "KIRANA BAZAR BRANCH",
            title1: 'Address : ',
            desc1:'At Kirana Bazar, Kasgateshwar Math Road Talikoti',
            title2:'Branch Manager : ',
            desc2:'Smt. S. G. Borannavar',
            latitude:'16.47680431003069',
            longitude:'76.30649615839245',
        },
        {
            title: "BALASHETTIHAL BRANCH",
            title1: 'Address : ',
            desc1:'Dist Yadgir Tq, Hunasagi, Balashettihal',
            title2:'Branch Manager : ',
            desc2:'Sri B S Kanakagiri',
            latitude:'16.476809711081923',
            longitude:'76.30651964915712',
        },
        
    ];

    const timings = "10.30 am - 5.30 pm";
    const lunch_timings = "2.30 pm - 3.30 pm"
  return (
    <div className='body'>
        <div className='heading'>
            BRANCHES
        </div>
        <hr/>
        <hr/>

        <div className= {styles.branches}>
        {branches.map(item => 
                            <GradientCardNoIcon title={item.title} title1={item.title1} desc1={item.desc1} title2={item.title2} desc2={item.desc2} latitude={item.latitude} longitude={item.longitude}/>
                        )}
        </div>

        <div className='break'/>
        
        <div className='break'/>

        <div className='heading'>
            BANK TIMINGS
        </div>
        <hr/>
        <hr/>

        <div className={styles.timings}>
            <div className={styles.highlight}>
                <FaClock/> {timings}
                <p>Lunch Timings : {lunch_timings}</p>
                <p>Holidays : 2nd/4th Saturday and Sundays</p>
            </div>
            
            
        </div>
    </div>
  )
}

export default BranchesTimings