import React from 'react'
import logo from '../assets/images/logo.png'
import { Link } from 'react-router-dom'
import "../styles/navbar.css"
import login_icon from '../assets/icons/login.svg'
import { MdMenu } from 'react-icons/md'
import { IoMdArrowDropdown } from 'react-icons/io'

function Navbar() {
    //All the items in the navbar(can be put in database to be editable)
    //For a navbar that has a dropdown, the link is of the first item
    const navLinks = [
        {
            link : "/",
            text : "Home",
            dropdown : false,
        },
        {
            link : "",
            text : "KNOW YOUR BANK",
            className : "know_your_bank",
            dropdown : [true, [
                {
                    link : "/about",
                    text : "ABOUT US",   
                },
                {
                    link : "/bod",
                    text : "BOARD OF DIRECTORS",   
                },
                {
                    link : "/management-executives",
                    text : "MANAGEMENT EXECUTIVES",   
                },
                {
                    link : "/branches-and-bank-timings",
                    text : "BRANCHES AND BANK TIMING",   
                },
                {
                    link : "/annual-report",
                    text : "ANNUAL REPORT",   
                },
                {
                    link : "/future-plans",
                    text : "FUTURE PLANS",   
                },
            ]],
        },

        {
            link : "",
            text : "INTEREST RATES",
            className:"interest_rates",
            dropdown : [true, [
                {
                    link : "/deposit-interest-rates",
                    text : "DEPOSIT INTEREST",   
                },
                {
                    link : "/loan-interest-rates",
                    text : "LOAN INTEREST",   
                },
            ]],
        },

        {
            link : "/loans",
            text : "LOANS",
            dropdown : false,
        },

        {
            link : "",
            text : "DEPOSITS",
            className : 'deposits',
            dropdown : [true, [
                {
                    link : "/deposits/savings-account",
                    text : "SAVING BANK ACCOUNT",   
                },
                {
                    link : "/deposits/current-account",
                    text : "CURRENT ACCOUNT",   
                },
                {
                    link : "/deposits/fixed-deposit",
                    text : "FIXED DEPOSITS & KCC",   
                },
                {
                    link : "/deposits/recurring-deposit",
                    text : "RECURRING DEPOSITS",   
                },
                {
                    link : "/deposits/daily-deposit",
                    text : "DAILY DEPOSITS",   
                },
            ]],
        },

        {
            link : "",
            text : "FACILITIES",
            className : 'facilities_list',
            dropdown : [true, [
                {
                    link : "/facilities/rtgs-neft",
                    text : "RTGS/NEFT",   
                },
                {
                    link : "/facilities/bank-guarantee",
                    text : "BANK GUARANTEE",   
                },
                {
                    link : "/facilities/membership",
                    text : "MEMBERSHIP",   
                },
                {
                    link : "/facilities/lockers",
                    text : "LOCKERS",   
                },
                {
                    link : "/facilities/abb_services",
                    text : "ABB SERVICES",   
                },
            ]],
        },

        {
            link : "/gallery",
            text : "GALLERY",
            dropdown : false,
        },

        {
            link : "/contact-us",
            text : "CONTACT US",
            dropdown : false,
        },
    ];

    function menu(){
        let navLinks = document.getElementsByClassName('navLinks')[0];
        navLinks.style.display = navLinks.style.display === "flex"? "none" : "flex";
    }

  return (
    <div className='nav'>
        <div className='left'>
            <Link to='/'><img className="logo" src={ logo }></img></Link>
            <MdMenu onClick={menu}/>
        </div>
        <div>
            <p className='title'>
                THE TALIKOTI SAHAKARI BANK NIYAMIT
            </p>
            <div className="navLinks">
                
                {navLinks.map(item => 
                    {
                        if(item.dropdown[0]){
                            return <div>
                            <Link>{item.text}</Link>
                            <li className={ item.className }>
                                {
                                    item.dropdown[1].map(i => <Link to= {i.link}>{i.text}</Link>)
                                }
                            </li>
                         </div>
                    }

                    else{
                        return <div>
                            <Link to={item.link}>{item.text}</Link>
                         </div>
                    }
                }

                    
                )
                }
            </div>
            
        </div>
        <Link className="login"><img src={ login_icon }></img></Link>
        

        
    </div>
  )
}

export default Navbar