import React, { useState } from "react";
import Popup from "reactjs-popup";
import Button from "./Button";
import { FaEdit } from "react-icons/fa";
import { FileInput } from "./TextInput";

function ModalFileInput(props) {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(!open);
  }
  return (
    <div className="modalDiv">
      <FaEdit onClick={onClick} />

      <Popup className="editModal" open={open} closeOnDocumentClick={false}>
        <a className="close" onClick={onClick}>
          &times;
        </a>
        <p>Edit</p>
        <div>

          <form id ={props.index} onSubmit={props.onSubmit}>
          <label for="heading">Heading</label>      
          <input type="text" id="heading"></input>

          <FileInput index = {props.index} label={props.label}/>

            <button>Submit</button>
          </form>
        </div>
      </Popup>
    </div>
  )

}


export default ModalFileInput;
