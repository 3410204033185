import React from 'react'
import Button from '../components/Button'
import styles from '../styles/pages/aboutuspage.module.css'
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import report from '../assets/Bank Themes.pdf'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

function display_pdf(){
    const pdf=document.querySelector(".pdf_display");
    console.log(pdf);

    if(pdf != null){
        if( pdf.style.display === "none"){
            pdf.style.display = "block";
        } else{
            pdf.style.display = "none";
        }
    }
    
}


function AnnualReport() {
    
  return (
    <div className='body'>
        <div className='heading'>
            ANNUAL REPORT
        </div>
        <hr/>
        <hr/>
        <div className={"body " + styles.report} id='2023'>
            <p>Annual Report 2023</p>
            <div className={styles.buttons}>
                <Button text="View" onClick = {display_pdf}/>
                <a href={report} download="report.pdf" > <Button text="Download"/></a>
            </div>
        </div>

        <div className='pdf_display' style={{display:"none"}}>
            <Document file={report} >
                <Page pageNumber={1} />
            </Document>
        </div>
        

    </div>
  )
}

export default AnnualReport