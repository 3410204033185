import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import Button from "./Button";
import { FaEdit, FaPlus } from "react-icons/fa";
import { TextInput } from "./TextInput";

function ModalArray(props) {
    const [open, setOpen] = useState(false);    
    const [content, setContent] = useState(props.value);
  
    const onClick = () => {
      setOpen(!open);
    }

    useEffect(() => {
      if(content.length == 0)
        setContent((props.value))
      
    }); 
    
    const add = (newItem) => {
      setContent(content.concat([newItem]));
    }

    return (
      
      <div className="modalDiv">
        <FaEdit onClick={onClick} />
  
        <Popup className="editModal" open={open} closeOnDocumentClick={false}>
          <a className="close" onClick={onClick}>
            &times;
          </a>
          <p>Edit</p>
          <div>
  
            <form onSubmit={e => {
              e.preventDefault(); 
              const data = new FormData(e.currentTarget);
              const newArray = content.map((item, id) => data.get(props.label+id))
              setContent(newArray);
              props.onSubmit(newArray);
              }}>

            {
              content.map((item, id) => {
                return <TextInput id={id} text = {item} label = {props.label}/>
              })
            }
  
                         
              <FaPlus onClick={() => add("New")}/>
              <button>Submit</button>
            </form>
          </div>
        </Popup>
      </div>
    )
}

  export default ModalArray;