// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer{

    height: fit-content;
    min-height: 200px;
    background-color: var(--primary);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 30px;
    color: white;
}
.footer .details{
    grid-column: 1;
    align-self: center;
}

.name{
        font-size: 25px;
        color: var(--secondary);
}

.address{
    font-size: 16px;
}

.resources{
    display: flex;
    flex-direction: column;
}
.resources p, .contact_us p{
    font-size: 25px;
    color: var(--secondary);
    margin-block: 10px;
    font-weight: 600;
}
.resources a, .contact_us .links div{
    text-decoration: none;
    font-size: 16px;
    line-height: 1.5;
    color: white;
    align-items: center;
    display: flex;
}

.resources a:hover{
    color: var(--secondary);
}

.contact_us svg{
    height: 20px;
    width: 20px;
    align-self: center;
    margin-inline: 5px;
}

`, "",{"version":3,"sources":["webpack://./src/styles/footer.css"],"names":[],"mappings":"AAAA;;IAEI,mBAAmB;IACnB,iBAAiB;IACjB,gCAAgC;IAChC,aAAa;IACb,kCAAkC;IAClC,aAAa;IACb,YAAY;AAChB;AACA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;QACQ,eAAe;QACf,uBAAuB;AAC/B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,eAAe;IACf,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".footer{\r\n\r\n    height: fit-content;\r\n    min-height: 200px;\r\n    background-color: var(--primary);\r\n    display: grid;\r\n    grid-template-columns: 1fr 1fr 1fr;\r\n    padding: 30px;\r\n    color: white;\r\n}\r\n.footer .details{\r\n    grid-column: 1;\r\n    align-self: center;\r\n}\r\n\r\n.name{\r\n        font-size: 25px;\r\n        color: var(--secondary);\r\n}\r\n\r\n.address{\r\n    font-size: 16px;\r\n}\r\n\r\n.resources{\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n.resources p, .contact_us p{\r\n    font-size: 25px;\r\n    color: var(--secondary);\r\n    margin-block: 10px;\r\n    font-weight: 600;\r\n}\r\n.resources a, .contact_us .links div{\r\n    text-decoration: none;\r\n    font-size: 16px;\r\n    line-height: 1.5;\r\n    color: white;\r\n    align-items: center;\r\n    display: flex;\r\n}\r\n\r\n.resources a:hover{\r\n    color: var(--secondary);\r\n}\r\n\r\n.contact_us svg{\r\n    height: 20px;\r\n    width: 20px;\r\n    align-self: center;\r\n    margin-inline: 5px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
