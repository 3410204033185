import React from 'react';
import styles from '../../styles/pages/facilities.module.css';
import { MdOutlineDoubleArrow } from 'react-icons/md'

function Lockers() {
  return (
    <div className='body'>
        <div>
        <div className='heading'>
            LOCKERS
        </div>
        <hr/>
        <hr/>
        <div className='body'>
        <div className={styles.text}>
        <p><MdOutlineDoubleArrow/> Locker can be opened by Individual / jointly.</p>
        
        <p>Features & Benefits:</p>
                
        <p><MdOutlineDoubleArrow/> Locker facility is available with nominal charges.</p>
        <p><MdOutlineDoubleArrow/> Minimum period is of one year.</p>
        <p><MdOutlineDoubleArrow/> Locker can be operated by Individual / jointly.</p>
        <p><MdOutlineDoubleArrow/> Nomination facility is available.</p>
        </div>
        </div>
        </div>
    </div>
  )
}

export default Lockers