import React from 'react'
import styles from '../../styles/pages/loans_deposits.module.css'
import { MdOutlineDoubleArrow } from 'react-icons/md'

function FixedDeposit() {
    let features = ["Minimum initial deposit amount is Rs.500/- with no Ceiling for the maximum deposit amount.",
"Deposit Period ranges from 1 year to 10 year.",
"Individual Deposit /Joint Deposit /Deposit in the name of Minors with guardian can be made.",
"The depositor desires to have interest payment on quarterly / half yearly or yearly basis, he should mention this in the Account Opening Form while opening the account. The Bank will take note of this and pay the interest amount accordingly.",
"Additional Interest Rate for senior citizen.",
"Nomination facility is available.",
"Loan can be granted up to 75% of the deposit amount against the pledge of Deposit as per existing norms.",
"In case of payment before maturity there will be 1% deduction"];

let documents = ["Passport",
                 "Driving license",
                 "PAN Card",
"Voter's Identity Card",
"NAREGA Job Card",
"Aadhar Card"];

  return (
    <div className='body'>
      <div className='heading'>
      FIXED DEPOSIT
      </div>
      <hr/>
      <hr/>
      <div className={styles.text}>
            <p>
            The Talikoti Sahakari Bank Providing Deposit Account Scheme which is Ideal for planning your financial commitments for the future.It has compounding of accrued interest which yields higher returns, making your investments grow faster.
            </p>
            <p>
            The bank have special Deposit Scheme called "Kamdhenu Cash Certificate".
            </p>
        </div>
      <div className='body bordered_box'>
      
        <div className={styles.subheading}>
            Eligibility 
        </div>
        <div className={styles.text}>
            <p>
            A Fixed Deposit Account can be opened by Individuals /Minors /Institutions /Associations /Registered Co-op. Societies /Trusts /HUFs.
            </p>
        </div>

        <div className='break'></div>

        <div className={styles.subheading}>
            Features & Benefits: 
        </div>
        <div className={styles.text}>
            {features.map(text => <p><MdOutlineDoubleArrow/>{text}</p>)}
        </div>

        <div className='break'></div>

        <div className={styles.subheading}>
            Officially Valid Documents:
        </div>
        <div className={styles.text}>
           <p> Two latest passport size photographs and any one of the officially valid documents as Identity and Address proof as below,</p>
            {documents.map(text => <p><MdOutlineDoubleArrow/>{text}</p>)}
        </div>
      </div>
    </div>
  )
}

export default FixedDeposit