import React from 'react'

function PigmyDeposit() {
    return (
        <div className='body'>
          <div className='heading'>
          PIGMY/DAILY DEPOSIT
          </div>
          <hr/>
          <hr/>
          <div className='body bordered_box'>
          <p>
            This scheme is introduced to help daily wage earners, small traders,as a way of funding their bigger capital requirements.
          </p>
          <p>
          In this scheme money can be deposited into an account on daily basis. The amount may be as small as Rupees 100. The unique characteristic of this scheme is that an agent from the bank collects money to be deposited, on daily basis, from doorsteps of account holder. It has provided many employment opportunities as agents. As per the bank’s norms, if the Daily Deposit account is closed before  6 months, no interest shall be paid, instead, 2% commission will be collected from the investor on the deposited amount.
          </p>
          </div>
        </div>
      )
}

export default PigmyDeposit