import React from 'react'
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import styles from '../styles/pages/contactus.module.css'

function ContactUs() {
  return (
    <div>
        <div className='body'>
        <div className='heading'>
            CONTACT US
        </div>
        <hr/>
        <hr/>

        <div className='body'>
            <div className={styles.highlight}>
                <p><FaMapMarkerAlt/> Omkar Chowk, Near Bijapur <br/>Circle,
                Talkoti 586214, <br/> Karnataka, India</p>
                <p><FaPhoneAlt/> : +91 8792057565</p>
               <p><MdEmail/> : ceo@talikotisahakaribank.in </p>
            </div>
        </div>
    </div>
    </div>
  )
}

export default ContactUs