// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery_page_gallery__iJWTX > div > div{
    background-color: var(--secondary_bg);
    margin-inline: 25px;
}

.gallery_page_gallery__iJWTX > div{
    padding-inline: 0px;
}

.gallery_page_gallery__iJWTX > div a img{
    width: 100%;
}

.gallery_page_gallery__iJWTX > div p{
    font-weight: 700;
    color: var(--secondary);
}

.gallery_page_gallery__iJWTX{
    height: 60vh;
}

.gallery_page_photo_gallery__dVbyL > div{
    max-width: 350px;
}

.gallery_page_photo_gallery__dVbyL > div p{
    font-size: 15px;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/gallery_page.module.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".gallery > div > div{\r\n    background-color: var(--secondary_bg);\r\n    margin-inline: 25px;\r\n}\r\n\r\n.gallery > div{\r\n    padding-inline: 0px;\r\n}\r\n\r\n.gallery > div a img{\r\n    width: 100%;\r\n}\r\n\r\n.gallery > div p{\r\n    font-weight: 700;\r\n    color: var(--secondary);\r\n}\r\n\r\n.gallery{\r\n    height: 60vh;\r\n}\r\n\r\n.photo_gallery > div{\r\n    max-width: 350px;\r\n}\r\n\r\n.photo_gallery > div p{\r\n    font-size: 15px;\r\n    font-weight: 600;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gallery": `gallery_page_gallery__iJWTX`,
	"photo_gallery": `gallery_page_photo_gallery__dVbyL`
};
export default ___CSS_LOADER_EXPORT___;
