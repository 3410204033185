// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interest_rates_page_table__VgGc3{
    background-color: var(--secondary_bg);
    margin-inline: 50px;
    padding-top: 20px;
    border-radius: 10px;
    border: 3px solid var(--secondary);
}

.interest_rates_page_wef__2NDeR{
    margin-inline: 50px ;
}

.interest_rates_page_table__VgGc3 > :nth-child(2n) {
    background-color: white;
}

.interest_rates_page_table__VgGc3 > :nth-child(2n + 3){
    background-color: rgb(222, 222, 222);
}

.interest_rates_page_row__7IA\\+C, .interest_rates_page_head__nf3jE{
    display: flex;
}

.interest_rates_page_head__nf3jE{
    border-bottom: 1.5px solid var(--secondary);
}

.interest_rates_page_head__nf3jE p{
    font-weight: 700;
}

.interest_rates_page_row__7IA\\+C p{
    color: var(--secondary);
    padding-inline: 40px;
    
}


.interest_rates_page_row__7IA\\+C .interest_rates_page_col1__P833s{
    flex: 3 1;
}

.interest_rates_page_row__7IA\\+C .interest_rates_page_col2__nBFNO{
    flex: 2 1;
}

.interest_rates_page_row__7IA\\+C .interest_rates_page_col3__SuHK2{
    flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/interest_rates_page.module.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;IACnB,kCAAkC;AACtC;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,oBAAoB;;AAExB;;;AAGA;IACI,SAAO;AACX;;AAEA;IACI,SAAO;AACX;;AAEA;IACI,SAAO;AACX","sourcesContent":[".table{\r\n    background-color: var(--secondary_bg);\r\n    margin-inline: 50px;\r\n    padding-top: 20px;\r\n    border-radius: 10px;\r\n    border: 3px solid var(--secondary);\r\n}\r\n\r\n.wef{\r\n    margin-inline: 50px ;\r\n}\r\n\r\n.table > :nth-child(2n) {\r\n    background-color: white;\r\n}\r\n\r\n.table > :nth-child(2n + 3){\r\n    background-color: rgb(222, 222, 222);\r\n}\r\n\r\n.row, .head{\r\n    display: flex;\r\n}\r\n\r\n.head{\r\n    border-bottom: 1.5px solid var(--secondary);\r\n}\r\n\r\n.head p{\r\n    font-weight: 700;\r\n}\r\n\r\n.row p{\r\n    color: var(--secondary);\r\n    padding-inline: 40px;\r\n    \r\n}\r\n\r\n\r\n.row .col1{\r\n    flex: 3;\r\n}\r\n\r\n.row .col2{\r\n    flex: 2;\r\n}\r\n\r\n.row .col3{\r\n    flex: 1;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `interest_rates_page_table__VgGc3`,
	"wef": `interest_rates_page_wef__2NDeR`,
	"row": `interest_rates_page_row__7IA+C`,
	"head": `interest_rates_page_head__nf3jE`,
	"col1": `interest_rates_page_col1__P833s`,
	"col2": `interest_rates_page_col2__nBFNO`,
	"col3": `interest_rates_page_col3__SuHK2`
};
export default ___CSS_LOADER_EXPORT___;
