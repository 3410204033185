import React from 'react'
import styles from '../styles/pages/aboutuspage.module.css'
import { MdOutlineDoubleArrow } from 'react-icons/md'

function FuturePlans() {
  return (
    <div className='body'>
        <div className='heading'>
            FUTURE PLANS
        </div>
        <hr/>
        <hr/>

        <div className='body'>
        <div className={`${styles.text} ${styles.future_plans} ${styles.highlight}`}>
        <p><MdOutlineDoubleArrow/>We are aimed to open few more branches in rural areas to provide banking facilities in rural.</p>
        
        <p><MdOutlineDoubleArrow/>Also, we have aimed to achieve target of Rs.200.00 Crore business in future.</p>
        
        
        <p><MdOutlineDoubleArrow/>We are planned to introduce digital payments shortly.</p>
        </div>
        </div>
    </div>
  )
}

export default FuturePlans