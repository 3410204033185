// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contactus_highlight__0IFed{
    padding: 20px;
    border: 2px var(--secondary) solid;
    background-color: var(--secondary_bg);
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    height: fit-content;
    overflow: hidden;
}

.contactus_highlight__0IFed p{
   
    display: flex;
    align-items: center;
}

.contactus_highlight__0IFed svg{
    height: 30px;
    margin-inline: 10px;
}


@media screen and (max-width: 600px){
    
    .contactus_highlight__0IFed p{
        line-height: 15px;
        margin-block: 0.5em;
    }
}

@media screen and (max-width: 600px){
    
    .contactus_highlight__0IFed p{
        line-height: 17px;
        margin-block: 0.5em;
    }
}


@media screen and (max-width: 768px){
    
    .contactus_highlight__0IFed p{
        line-height: 18px;
    }
}


@media screen and (max-width: 1200px){
    
    .contactus_highlight__0IFed p{
        line-height: 30px;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/contactus.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kCAAkC;IAClC,qCAAqC;IACrC,wCAAwC;IACxC,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;;IAEI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;;AAGA;;IAEI;QACI,iBAAiB;QACjB,mBAAmB;IACvB;AACJ;;AAEA;;IAEI;QACI,iBAAiB;QACjB,mBAAmB;IACvB;AACJ;;;AAGA;;IAEI;QACI,iBAAiB;IACrB;AACJ;;;AAGA;;IAEI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".highlight{\r\n    padding: 20px;\r\n    border: 2px var(--secondary) solid;\r\n    background-color: var(--secondary_bg);\r\n    box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);\r\n    border-radius: 10px;\r\n    height: fit-content;\r\n    overflow: hidden;\r\n}\r\n\r\n.highlight p{\r\n   \r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.highlight svg{\r\n    height: 30px;\r\n    margin-inline: 10px;\r\n}\r\n\r\n\r\n@media screen and (max-width: 600px){\r\n    \r\n    .highlight p{\r\n        line-height: 15px;\r\n        margin-block: 0.5em;\r\n    }\r\n}\r\n\r\n@media screen and (max-width: 600px){\r\n    \r\n    .highlight p{\r\n        line-height: 17px;\r\n        margin-block: 0.5em;\r\n    }\r\n}\r\n\r\n\r\n@media screen and (max-width: 768px){\r\n    \r\n    .highlight p{\r\n        line-height: 18px;\r\n    }\r\n}\r\n\r\n\r\n@media screen and (max-width: 1200px){\r\n    \r\n    .highlight p{\r\n        line-height: 30px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlight": `contactus_highlight__0IFed`
};
export default ___CSS_LOADER_EXPORT___;
