import React from 'react'
import SimpleCard from '../components/SimpleCard_extraText';
import B_K_Mannur from '../assets/images/B_K_Mannur.png'
import S_S_Gadigeppagol from '../assets/images/S_S_Gadigeppagol.png'
import logo from '../assets/images/logo.png'
import styles from '../styles/pages/aboutuspage.module.css'

function ManagementExecutives() {
    const executives =[
        {
            name: "SMT. B. K. MANNUR",
            title : "GENERAL MANAGER",
            img : B_K_Mannur,
        },
        {
            name: "SHRI. S. S. GADIGEPPAGOL",
            title : "MANAGER",
            img : S_S_Gadigeppagol,
        }
    ];
    const Extra = () => <img className={styles.logo_bg_side} src={logo}/>;
  return (
    <div className='body'>

        <div className='heading'>
            MANAGEMENT EXECUTIVES 
        </div>
        <hr/>
        <hr/>
        <div className='body'>
        <div className={styles.executives}>
            <SimpleCard list={executives} extra={Extra}/>
        </div>
        </div>
    </div>
  )
}

export default ManagementExecutives